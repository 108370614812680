<template>
  <div>
    <div
      class="card-body"
      :class="{'was-validated': $v.value.$dirty}">
      <template v-if="edit">
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Material type <i v-b-tooltip="MATERIAL_TYPE" class="fe fe-info"></i> <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <select
              v-model="$v.value.material_type.$model"
              class="form-control custom-select"
              :class="{'is-invalid': $v.value.material_type.$error}"
              :disabled="this.isGNFR"
              @change="changedMaterialType()">
              <option :value="null">Select material type</option>
              <option
                v-for="mType in materialTypes"
                :key="mType.id"
                :value="mType">
                {{capitalize(lowerCase(mType.name))}}
              </option>
            </select>
            <template v-if="$v.value.material_type.$dirty">
              <div v-if="!$v.value.material_type.required" class="invalid-feedback d-block">Material type is required</div>
            </template>
          </div>
        </div>

        <div v-if="isGNFR" class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">GNFR type</label>
          <div class="col-sm-7">
            <select
              v-model="value.gnfr_type"
              class="form-control custom-select"
              @change="handleChangeGnfrType()"
            >
              <option :value="null">Select gnfr type</option>
              <option
                v-for="gType in gnfrTypes"
                :key="gType.id"
                :value="gType">
                {{gType.name}}
              </option>
            </select>
          </div>
        </div>

        <template v-if="isSubrecipe">
          <div class="form-group row d-flex">
            <label class="col-sm-3 col-form-label text-right font-weight-bold">Main ingredient <span class="text-danger">*</span></label>
            <div class="col-sm-7">
              <autocomplete
                v-model="$v.value.ingredient.$model"
                :list.sync="ingredients"
                :nextPage="nextIngredients"
                :class="{'is-invalid': $v.value.ingredient.$error}"
                label="name"
                entity="ingredient"
                :disabled="disableIngredient"
              >
              </autocomplete>
              <template v-if="$v.value.ingredient.$dirty">
                <div v-if="!$v.value.ingredient.required" class="invalid-feedback d-block">Ingredient is required</div>
              </template>
            </div>
          </div>
          <div
            v-for="(dependency, index) in $v.value.dependencies.$each.$iter"
            :key="index"
            class="row d-flex">
            <label class="col-sm-3"><wbr></label>
            <div class="col-sm-7">
              <div class="card">
                <div v-if="value.dependencies.length > 1" class="d-flex justify-content-end p-2">
                  <a
                    href="#"
                    class="text-danger special-small d-flex align-items-center"
                    @click.prevent="removeDependentIngredient(index)"><i class="fe fe-x mr-1"></i> remove</a>
                </div>
                <div class="p-4">
                  <div class="row d-flex">
                    <label class="col-auto col-form-label font-weight-bold">Sub-ingredient <span class="text-danger">*</span></label>
                    <div class="col">
                      <div class="form-group">
                        <autocomplete
                          v-model="dependency.ingredient.$model"
                          :list.sync="ingredients"
                          :nextPage="nextIngredients"
                          :class="{'is-invalid': dependency.ingredient.$error}"
                          label="name"
                          placeholder="Select ingredient"
                          entity="ingredient"
                          @input="data => changedIngredient(data, value.dependencies[index], index)"
                        >
                        </autocomplete>
                        <template v-if="dependency.ingredient.$dirty">
                          <div v-if="!dependency.ingredient.required" class="invalid-feedback d-block">Ingredient is required</div>
                        </template>
                      </div>
                      <div class="row d-flex">
                        <div class="col-8">
                          <label class="form-label">Purchasable article <span class="text-danger">*</span></label>
                          <select
                            v-model="value.dependencies[index].article"
                            class="form-control custom-select"
                            :class="{'is-invalid': dependency.article.$error}">
                            <option
                              :value="null"
                              class="placeholder"
                              disabled>
                              Select purchasable article
                            </option>
                            <option
                              v-for="article in value.dependencies[index].purchasableArticles"
                              :key="article.id"
                              :value="article">
                              {{article.name}}
                            </option>
                          </select>
                        </div>
                        <div class="col-4">
                          <label class="form-label">Percentage <span class="text-danger">*</span></label>
                          <div class="input-group">
                            <input
                              v-model.number="value.dependencies[index].percentage"
                              type="number"
                              class="form-control">
                            <div class="input-group-append">
                              <div class="input-group-text">%</div>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <template v-if="dependency.article.$dirty">
                            <div v-if="!dependency.article.required" class="invalid-feedback d-block">Purchasable article is required</div>
                          </template>
                          <template v-if="dependency.percentage.$dirty">
                            <div v-if="!dependency.percentage.required" class="invalid-feedback d-block">Percentage is required</div>
                            <div v-if="!dependency.percentage.maxValue" class="invalid-feedback d-block">Must be less than 100 percent</div>
                            <div v-if="!dependency.percentage.maxTotal" class="invalid-feedback d-block">Total percentage must have a total of 100 percent</div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row d-flex">
            <label class="col-sm-3"><wbr></label>
            <div class="col-sm-7">
              <a href="#" @click.prevent="addIngredient"><i class="fe fe-plus-circle mr-1"></i> Add another sub-ingredient</a>
            </div>
          </div>
        </template>
        <template v-else-if="isPackable">
          <div
            v-for="(dependency, index) in $v.value.dependencies.$each.$iter"
            :key="index"
            class="row d-flex">
            <label class="col-sm-3"><wbr></label>
            <div class="col-sm-7">
              <div class="card">
                <div v-if="value.dependencies.length > 1" class="d-flex justify-content-end p-2">
                  <a
                    href="#"
                    class="text-danger special-small d-flex align-items-center"
                    @click.prevent="removeDependentIngredient(index)"><i class="fe fe-x mr-1"></i> remove</a>
                </div>
                <div class="p-4">
                  <div class="row d-flex">
                    <div class="col">
                      <label class="form-label">Main ingredient <span class="text-danger">*</span></label>
                      <div class="form-group">
                        <autocomplete
                          :list.sync="ingredients"
                          :nextPage="nextIngredients"
                          :class="{'is-invalid': $v.value.ingredient.$error}"
                          :value="value.ingredient"
                          label="name"
                          entity="ingredient"
                          :disabled="disableIngredient"
                          @input="data => changedIngredient(data, value, index)"
                        >
                        </autocomplete>
                        <template v-if="$v.value.ingredient.$dirty">
                          <div v-if="!$v.value.ingredient.required" class="invalid-feedback d-block">Ingredient is required</div>
                        </template>
                      </div>
                      <div class="row d-flex">
                        <div class="col-9">
                          <label class="form-label">Purchasable article <span class="text-danger">*</span></label>
                          <select
                            v-model="value.dependencies[index].article"
                            class="form-control custom-select"
                            :class="{'is-invalid': dependency.article.$error}"
                            @change="changePurchasableArticle(value.dependencies[index].article)">
                            <option
                              :value="null"
                              class="placeholder"
                              disabled>
                              Select purchasable article
                            </option>
                            <option
                              v-for="article in value.dependencies[index].purchasableArticles"
                              :key="article.id"
                              :value="article">
                              {{article.name}}
                            </option>
                          </select>
                        </div>
                        <div class="col-3">
                          <label class="form-label">Percentage <span class="text-danger">*</span></label>
                          <div class="input-group">
                            <input
                              v-model.number="value.dependencies[index].percentage"
                              type="number"
                              class="form-control"
                              disabled>
                            <div class="input-group-append">
                              <div class="input-group-text">%</div>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <template v-if="dependency.article.$dirty">
                            <div v-if="!dependency.article.required" class="invalid-feedback d-block">Purchasable article is required</div>
                          </template>
                          <template v-if="dependency.percentage.$dirty">
                            <div v-if="!dependency.percentage.required" class="invalid-feedback d-block">Percentage is required</div>
                            <div v-if="!dependency.percentage.maxValue" class="invalid-feedback d-block">Must be less than 100 percent</div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-else-if="value.material_type" class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Ingredient <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <autocomplete
              v-model="$v.value.ingredient.$model"
              :list.sync="ingredients"
              :nextPage="nextIngredients"
              :class="{'is-invalid': $v.value.ingredient.$error}"
              label="name"
              entity="ingredient"
              :disabled="disableIngredient"
            >
            </autocomplete>
            <template v-if="$v.value.ingredient.$dirty">
              <div v-if="!$v.value.ingredient.required" class="invalid-feedback d-block">Ingredient is required</div>
            </template>
          </div>
        </div>

        <div v-if="isPackable" class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Packaging article</label>
          <div class="col-sm-7">
            <autocomplete
              v-model="value.packaging_article"
              :list.sync="packagingArticles"
              :nextPage="nextPackagingArticle"
              label="name"
              entity="packaging article"
            >
            </autocomplete>
          </div>
        </div>

        <div v-if="canSelectPackagingMaterial" class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Packaging material</label>
          <div class="col-sm-7">
            <select
              v-model="value.packaging_material"
              class="form-control custom-select">
              <option :value="null">Select packaging material</option>
              <option
                v-for="packagingType in packagingMaterials"
                :key="packagingType.id"
                :value="packagingType">
                {{packagingType.name}}
              </option>
            </select>
          </div>
        </div>

      </template>
      <template v-else>
        <form-description>
          <label class="text-right font-weight-bold col-sm-5">Material type <i v-b-tooltip="MATERIAL_TYPE" class="fe fe-info"></i> :</label>
          <div class="col-sm-7">{{capitalize(lowerCase((value.material_type || {}).name))}}</div>
        </form-description>

        <form-description
          v-if="value.gnfr_type"
          label="GNFR Type"
          :value="value.gnfr_type.name"/>

        <form-description label="Ingredient" :value="(value.ingredient || {}).name"/>

        <form-description>
          <label class="text-right font-weight-bold col-sm-5">Measured in recipes by <i v-b-tooltip="MEASURED_IN_RECIPES_BY" class="fe fe-info"></i> :</label>
          <div class="col-sm-7">{{(value.ingredient || {}).measured_in_recipe_by}}</div>
        </form-description>

        <form-description v-if="isPackable || isSubrecipe">
          <label v-if="isPackable" class="text-right font-weight-bold col-sm-5">Purchasable article:</label>
          <label v-else class="text-right font-weight-bold col-sm-5">Sub-ingredients:</label>
          <div class="col-sm-5">
            <table class="table table-sm table-striped table-vcenter">
              <thead>
                <tr>
                  <th>Article</th>
                  <th class="text-center">Percentage</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(dependency, index) in value.dependencies" :key="index">
                  <td>{{(dependency.article || {}).name || 'N/A'}}</td>
                  <td class="text-center">{{dependency.percentage}}%</td>
                </tr>
                <tr v-if="!value.dependencies.length">
                  <td colspan="2" class="text-center">No articles available</td>
                </tr>
              </tbody>

            </table>
          </div>
        </form-description>

        <form-description
          v-if="value.packaging_material"
          label="Packaging materials"
          :value="value.packaging_material.name"/>

        <form-description
          v-if="isPackable && value.packaging_article"
          label="Packaging article"
          :value="value.packaging_article.name"/>

      </template>
    </div>
    <div v-if="edit" class="card-footer d-flex">
      <button class="btn btn-primary ml-auto" @click.prevent="validate">Apply</button>
    </div>
  </div>
</template>

<script>

import {articles, materialTypes, mainIngredients, packagingMaterials} from '@/services';
import Autocomplete from '@/components/Autocomplete';
import FormDescription from '@/components/FormDescription';
import {required, requiredIf, maxValue} from 'vuelidate/lib/validators';

const MEASURED_IN_RECIPES_BY = 'This is the article’s unit of measurement in recipes. Quantity is used for articles like dates and eggs. Weight is used for articles like flour, paprika.';
const MATERIAL_TYPE = 'For purchasable material type, make sure to add the quantity per net weight and yield.';

export default {

  components: {

    Autocomplete,
    FormDescription,
  },
  props: {
    isPackable: {
      default: false,
      type: Boolean,
    },
    isGNFR: {
      default: false,
      type: Boolean,
    },
    disableIngredient: {
      default: false,
      type: Boolean,
    },
    edit: {
      default: false,
      type: Boolean,
    },
    value: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      MEASURED_IN_RECIPES_BY,
      MATERIAL_TYPE,

      ingredients: [],
      packagingArticles: [],
      materialTypes: [],
      gnfrTypes: [],
      packagingMaterials: [],
      purchasableId: null,
      withParams: [
        'nutritions',
        'allergens',
        'countries',
        'compositions',
      ],
    };
  },
  computed: {
    canSelectPackagingMaterial() {
      return this.value?.gnfr_type?.name === 'Packaging material' && this.isGNFR;
    },
    isSubrecipe() {
      return !this.value.material_type?.is_purchasable &&
                    this.value.material_type?.has_multiple_purchasable &&
                    this.value.material_type?.is_recipe_material;
    },
    purchasableMaterial() {
      return this.materialTypes.find(item => item.is_purchasable && !item.is_recipe_material && item.name.includes('purchasable')) || {};
    },
  },
  watch: {
    'value.dependencies'(value) {
      if (value.length && this.purchasableMaterial) {
        this.getDependencyArticles();
      }
    },
    // when user switches material type to GNFR
    isGNFR(value) {
      if (value) {
        this.loadGnfrRelations();
      }
    },
    // when user enters material type editing mode
    edit(value) {
      if (value && this.isGNFR) {
        this.loadGnfrRelations();
      }
    },
  },
  async created() {
    mainIngredients.getByParameters({column: 'name', direction: 'asc', allowed_in_recipes: !this.isGNFR}).then(result => this.ingredients = result);

    // required to have material types ready on created
    this.materialTypes = (await materialTypes.getByParameters({column: 'name', direction: 'asc'})).items;
    articles.getByParameters({column: 'name', direction: 'asc', gnfr_type_id: 1}).then(result => this.packagingArticles = result);

    // if GNFR, don't allow users to select other material types
    if (this.isGNFR) {
      this.$v.value.material_type.$model = this.materialTypes.find(type => type.name === 'gnfr') ?? '';
      this.loadGnfrRelations();
    }

    // initially grab purchasable articles if it has a dependencies
    if (this.value?.dependencies?.length) {
      this.getDependencyArticles();
    }
  },
  mounted() {
    // on init capture dependencies purchasable articles and material type
    if (!this.value.dependencies.length) { this.changedMaterialType(); }
  },
  methods: {
    addIngredient() {
      this.value.dependencies.push({
        article: null,
        ingredient: null,
        ingredients: [],
        percentage: null,
        purchasableArticles: [],
      });
    },
    handleChangeGnfrType() {
      // when a user changes the gnfr type we need to reset the packaging material
      // to force user on re selecting the packaging material
      this.value.packaging_material = null;
    },
    // only loads gnfr related relations if they're not loaded already
    loadGnfrRelations() {
      if (!this.gnfrTypes.length) {
        materialTypes.getGnfrTypes().then(result => this.gnfrTypes = result);
      }

      if (!this.packagingMaterials.length) {
        packagingMaterials.getByParameters().then(result => this.packagingMaterials = result.packaging_materials);
      }
    },
    async changedIngredient(data, article, index) {
      Object.assign(article, {ingredient: data});

      const result = await articles.purchasableArticles(data.id, this.purchasableMaterial.id, {params: {with: this.withParams.join(',')}});

      Object.assign(this.value.dependencies[index], {

        article: null,
        purchasableArticles: result.items,
      });
    },
    async changedMaterialType() {
      this.value.dependencies = [];

      await this.$nextTick();

      if (this.isPackable || this.isSubrecipe) {
        this.value.dependencies.push({
          article: null,
          ingredient: null,
          ingredients: [],
          percentage: this.isPackable ? 100 : null,
          purchasableArticles: [],
          article_allergens: [],
        });
      }

      // grab purchasable articles if it has a dependencies
      if (this.value?.dependencies?.length) {
        this.getDependencyArticles();
      }
    },
    changePurchasableArticle(data) {
      if (data.nutritions) {
        this.value.nutritions = data.nutritions;
      }

      if (data.allergens) {
        this.value.allergens = this.value.dependencies.flatMap(item => item.article.allergens || []);
      }

      if (data.components) {
        this.value.components = this.value.dependencies.flatMap(item => item.article.components || []);
      }

      this.value.shelf_life_expiration_date = this.value.dependencies[0]?.article.shelf_life_expiration_date;
      this.value.expiry_time_after_packing = this.value.dependencies[0]?.article.expiry_time_after_packing;
      this.value.production_lead_time = this.value.dependencies[0]?.article.production_lead_time;

      if (data.countries && data.is_import) {
        this.value.countries = data.countries;
        this.value.is_import = data.is_import;
      }
    },
    getDependencyArticles() {
      this.value.dependencies.forEach((item, index) => {
        if (this.isPackable) {
          this.getPurchasableArticles(this.value.ingredient, item);
        }
        else if (this.isSubrecipe && item.ingredient) {
          this.getPurchasableArticles(item.ingredient, item);
        }
      });
    },
    async getPurchasableArticles(data, dependency) {
      const result = await articles.purchasableArticles(
        data.id,
        this.purchasableMaterial.id,
        {params: {with: this.withParams.join(',')}},
      );

      const selectedArticle = result.items.find(article => article.id === dependency.article?.id) || null;

      Object.assign(dependency, {

        article: selectedArticle,
        purchasableArticles: result.items,
      });
    },
    async nextPackagingArticle(page, query) {
      const params = {

        column: 'name',
        direction: 'asc',
        gnfr_type_id: 1,
        page,
      };

      if (query.length) {
        params.query = query;
        delete params.column;
        delete params.direction;
      }

      return await articles.getByParameters(params);
    },
    async nextIngredients(page, query) {
      const params = {

        column: 'name',
        direction: 'asc',
        page,
      };

      if (query.length) {
        params.query = query;
        delete params.column;
        delete params.direction;
      }

      const result = await mainIngredients.getByParameters(params);

      return result;
    },
    removeDependentIngredient(index) {
      this.value.dependencies.splice(index, 1);
    },
    validate() {
      this.$v.value.$touch();

      if (!this.$v.value.$invalid) { this.$emit('valid'); }
    },
  },
  validations: {
    value: {
      dependencies: {
        $each: {
          article: {

            required,
          },
          ingredient: {

            required: requiredIf(function(value) {
              return this.isSubrecipe;
            }),
          },
          percentage: {
            maxTotal: function(value) {
              // TODO: temp fix for now
              // const totalPercentage = this.value.dependencies.reduce((accumulated, current) => accumulated + parseInt(current.percentage || 0), 0);
              return true;
            },
            maxValue: maxValue(100),
            required,
          },
        },
        required: requiredIf(function(value) {
          return this.isPackable || this.isSubrecipe;
        }),
      },
      ingredient: {

        required,
      },
      material_type: {

        required,
      },
    },
  },
};

</script>

<style scoped>
    .card-ingredient {
        border: 1px solid #ccc;
        width: auto;
    }
    .special-small {
        font-size: 0.7rem;
    }

    table thead th {

        font-weight: 600;
        background: #f4f6fa;
    }
</style>
