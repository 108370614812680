<template>
  <div>
    <div class="card-body" :class="{'was-validated': $v.anyDirty}">
      <div class="form-group text-center">
        <label class="custom-control custom-checkbox">
          <input
            v-model="noAllergens"
            :disabled="!isPurchasable"
            type="checkbox"
            class="custom-control-input"
            @change="removeSelectedAllergens">
          <span class="custom-control-label">No Allergens</span>
        </label>
      </div>
      <hr>
      <ul class="custom-listing">
        <li
          v-for="allergen in allergens"
          :key="allergen.id"
          class="form-group">
          <label class="custom-control custom-checkbox ">
            <input
              :checked="selectedAllergenIds.includes(allergen.id)"
              :disabled="noAllergens || !isPurchasable"
              type="checkbox"
              class="custom-control-input"
              @change="toggleAllergens(allergen)">
            <span class="custom-control-label">{{allergen.position}}. {{allergen.name}}</span>
          </label>
        </li>
      </ul>

      <template v-if="$v.$anyDirty && $v.$anyError">
        <div v-if="$v.$anyError" class="invalid-feedback d-block">Allergens is required</div>
      </template>
    </div>
  </div>
</template>

<script>

import {articles} from '@/services';

export default {
  props: {
    errors: {
      default: () => {},
      type: Object,
    },
    value: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      allergens: [],
      noAllergens: false,
    };
  },
  computed: {
    selectedAllergenIds() {
      return this.value.allergens.map(allergen => {
        if (typeof allergen === 'object' && !Array.isArray(allergen)) {
          return allergen.id;
        }

        // allergen requisition just returns id
        return allergen;
      });
    },
    isPurchasable() {
      return this.value.material_type?.is_purchasable;
    },
    isNew() {
      return this.value.status === 'draft';
    },
  },
  watch: {
    'value.material_type'(newVal, oldVal) {
      // if the material type is same then ignore
      if (newVal?.id === oldVal?.id) return;

      // watch on material type change to reset the selected allergens
      this.removeSelectedAllergens();
      this.noAllergens = false;

      // if its new recipe and the material type is not purchasable, set the no allergens checkbox
      if ((this.isNew && !this.isPurchasable)) {
        this.noAllergens = true;
      }
    },
    'value.allergens.length'(value) {
      // watch on allergens change to reset the no allergens checkbox
      if (value && this.noAllergens) {
        this.noAllergens = false;
      }
    },
  },
  async created() {
    this.allergens = await articles.getAllergens({column: 'name', direction: 'asc'});
    if ((!this.selectedAllergenIds.length && !this.isNew) || (!this.selectedAllergenIds.length && !this.isPurchasable)) {
      this.noAllergens = true;
    }
  },
  methods: {
    validate() {
      this.$v.$touch();
      if (!this.$v.invalid) {
        this.$emit('valid');
      }
    },
    removeSelectedAllergens() {
      this.value.allergens = [];
    },
    toggleAllergens(allergen) {
      const allergenIndex = this.selectedAllergenIds.indexOf(allergen.id);
      if (allergenIndex > -1) {
        this.value.allergens.splice(allergenIndex, 1);
      }
      else {
        this.value.allergens.push(allergen);
      }
    },
  },
  validations: {
    noAllergens: {
      checked: function() {
        return this.value.allergens.length || this.noAllergens;
      },
    },
    value: {
      allergens: {
        checked: function() {
          return this.value.allergens.length || this.noAllergens;
        },
      },
    },
  },
};

</script>

<style scoped>
.custom-listing {
  column-count: 1;
}

@media (min-width: 768px) {
  .custom-listing {
    column-count: 2;
  }
}

@media (min-width: 1280px) {
  .custom-listing {
    column-count: 5;
  }
}
</style>
