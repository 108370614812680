<template>

  <div>
    <div class="d-flex flex-column">
      <small
        v-for="request in replenishment.pending_cost_price_changes"
        :key="request.id"
        class="text-secondary mr-1"
        data-test="text-price-change">
        Price change to AED
        <b>{{numeral(request.to_cost_price).format('0,0[.]00')}}</b> from
        <b>{{request.effective_date}}</b> is <b
          :class="priceChangeRequestStatus[request.status]">{{request.status}}</b>
      </small>
    </div>
    <a
      href="#"
      class="btn-link"
      @click.self.prevent="showThis">Request Price Change
    </a>
    <b-modal
      v-model="showModel"
      centered
      title="Price Change Request"
      size="md"
      hide-footer
      id="modal-log-table"
      @hide="resetForm">
      <template slot="modal-header-close">&nbsp;</template>
      <dimmer :active="loading">
        <form ref="form" @submit.prevent="submit">
          <div class="row">
            <div class="col">
              <div class="card-body">
                <div class="form-group row d-flex">
                  <label class="col-sm-4 col-form-label text-right font-weight-bold">
                    Supplier <span class="text-danger">*</span>
                  </label>
                  <div class="col-sm-8">
                    <input
                      :value="replenishment.supplier.name"
                      type="text"
                      data-test="input-supplier-name"
                      class="form-control"
                      required
                      disabled/>
                  </div>
                </div>
                <div class="form-group row d-flex">
                  <label class="col-sm-4 col-form-label text-right font-weight-bold">
                    New price <span class="text-danger">*</span>
                  </label>
                  <div class="col-sm-8">
                    <input
                      v-model.number="form.toCostPrice"
                      ref="toCostPriceInput"
                      type="number"
                      data-test="input-to-cost-price"
                      step="any"
                      min="0"
                      required
                      class="form-control"/>
                  </div>
                </div>
                <div class="form-group row d-flex">
                  <label class="col-sm-4 col-form-label text-right font-weight-bold">
                    Effective date <span class="text-danger">*</span>
                  </label>
                  <div class="col-sm-8">
                    <input
                      v-model="form.effectiveDate"
                      type="date"
                      data-test="input-effective-date"
                      class="form-control"
                      :min="moment().format('YYYY-MM-DD')"
                      required/>
                  </div>
                </div>
                <div class="form-group row d-flex">
                  <label class="col-sm-4 col-form-label text-right font-weight-bold">
                    Comment
                  </label>
                  <div class="col-sm-8">
                    <textarea
                      v-model="form.comment"
                      class="form-control"
                      data-test="txt-comment"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="card-footer d-flex py-2">
                <button type="submit" class="btn btn-primary ml-auto">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </dimmer>
    </b-modal>
  </div>
</template>

<script>
import {articles} from '@/services';

export default {
  props: {
    replenishment: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      showModel: false,
      form: {
        toCostPrice: null,
        effectiveDate: null,
        comment: null,
      },
      priceChangeRequestStatus: {
        'pending': 'text-warning',
        'approved': 'text-success',
      },
    };
  },
  methods: {
    showThis() {
      this.showModel = true;
      setTimeout(() => {
        this.$refs.toCostPriceInput.focus();
      });
    },
    async submit() {
      try {
        this.loading = true;
        // get the article id and replenishment id
        const {id, article_id} = this.replenishment;
        await articles.requestCostPriceChange(article_id, id, {
          to_cost_price: this.form.toCostPrice,
          effective_date: this.form.effectiveDate,
          comment: this.form.comment,
        });
        // update the price change request
        this.showModel = false;
        this.$toasted.success('Cost price change request submitted successfully!');
        this.$emit('submit');
        // reset form
        this.resetForm();
      }
      catch (ex) {
        console.error(ex);
        this.$toasted.error(`Something went wrong, ${Object.values(ex.response?.data?.errors || []).join(',')}`);
      }
      finally {
        this.loading = false;
      }
    },
    resetForm() {
      // reset the form data
      this.form = {
        toCostPrice: null,
        effectiveDate: null,
        comment: null,
      };
    },
  },
};
</script>

<style scoped>
.btn-link {
  cursor: pointer;
  font-weight: 600;
  letter-spacing: .03em;
  font-size: 0.8125rem;
  min-width: 2.375rem;
}
</style>
