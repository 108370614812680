<template>

  <div>
    <div class="card-body" :class="{'was-validated': $v.value.nutritions.$dirty}">
      <template v-if="edit">
        <div class="row">
          <div class="col-6">
            <div class="form-group row d-flex">
              <label class="col-sm-5 col-form-label">Weight <span class="text-danger">*</span></label>
              <div class="col-sm-7">
                <div class="input-group">
                  <input
                    v-model="$v.value.nutritions.weight.$model"
                    type="number"
                    class="form-control"
                    :class="{'is-invalid': $v.value.nutritions.weight.$error}"/>
                  <div class="input-group-append">
                    <span class="input-group-text">{{(value.net_weight_uom || {}).name || 'Unit'}}</span>
                  </div>
                </div>
                <template v-if="$v.value.nutritions.weight.$dirty">
                  <div v-if="!$v.value.nutritions.weight.minValue" class="invalid-feedback d-block">Must be greater or equal to 0</div>
                  <div v-if="!$v.value.nutritions.weight.required" class="invalid-feedback d-block">Weight is required</div>
                </template>
              </div>
            </div>
            <div class="form-group row d-flex">
              <label class="col-sm-5 col-form-label">Energy KCal <span class="text-danger">*</span></label>
              <div class="col-sm-7">
                <input
                  v-model="$v.value.nutritions.energyKCal.$model"
                  type="number"
                  step="any"
                  class="form-control"
                  :class="{'is-invalid': $v.value.nutritions.energyKCal.$error}"/>
                <template v-if="$v.value.nutritions.energyKCal.$dirty">
                  <div v-if="!$v.value.nutritions.energyKCal.minValue" class="invalid-feedback d-block">Must be greater or equal to 0</div>
                  <div v-if="!$v.value.nutritions.energyKCal.required" class="invalid-feedback d-block">energyKCal is required</div>
                </template>
              </div>
            </div>
            <div class="form-group row d-flex">
              <label class="col-sm-5 col-form-label">Energy KJ <span class="text-danger">*</span></label>
              <div class="col-sm-7">
                <input
                  v-model="$v.value.nutritions.energyKJ.$model"
                  type="number"
                  step="any"
                  class="form-control"
                  :class="{'is-invalid': $v.value.nutritions.energyKJ.$error}"/>
                <template v-if="$v.value.nutritions.energyKJ.$dirty">
                  <div v-if="!$v.value.nutritions.energyKJ.minValue" class="invalid-feedback d-block">Must be greater or equal to 0</div>
                  <div v-if="!$v.value.nutritions.energyKJ.required" class="invalid-feedback d-block">EnergyKJ is required</div>
                </template>
              </div>
            </div>
            <div class="form-group row d-flex">
              <label class="col-sm-5 col-form-label">Fat <span class="text-danger">*</span></label>
              <div class="col-sm-7">
                <input
                  v-model="$v.value.nutritions.fat.$model"
                  type="number"
                  step="any"
                  class="form-control"
                  :class="{'is-invalid': $v.value.nutritions.fat.$error}"/>
                <template v-if="$v.value.nutritions.fat.$dirty">
                  <div v-if="!$v.value.nutritions.fat.minValue" class="invalid-feedback d-block">Must be greater or equal to 0</div>
                  <div v-if="!$v.value.nutritions.fat.required" class="invalid-feedback d-block">Fat is required</div>
                </template>
              </div>
            </div>
            <div class="form-group row d-flex">
              <label class="col-sm-5 col-form-label">Saturates <span class="text-danger">*</span></label>
              <div class="col-sm-7">
                <input
                  v-model="$v.value.nutritions.saturates.$model"
                  type="number"
                  step="any"
                  class="form-control"
                  :class="{'is-invalid': $v.value.nutritions.saturates.$error}"/>
                <template v-if="$v.value.nutritions.saturates.$dirty">
                  <div v-if="!$v.value.nutritions.saturates.minValue" class="invalid-feedback d-block">Must be greater or equal to 0</div>
                  <div v-if="!$v.value.nutritions.saturates.required" class="invalid-feedback d-block">Saturates is required</div>
                </template>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row d-flex">
              <label class="col-sm-5 col-form-label">Carbohydrates <span class="text-danger">*</span></label>
              <div class="col-sm-7">
                <input
                  v-model="$v.value.nutritions.carbohydrates.$model"
                  type="number"
                  step="any"
                  class="form-control"
                  :class="{'is-invalid': $v.value.nutritions.carbohydrates.$error}"/>
                <template v-if="$v.value.nutritions.carbohydrates.$dirty">
                  <div v-if="!$v.value.nutritions.carbohydrates.minValue" class="invalid-feedback d-block">Must be greater or equal to 0</div>
                  <div v-if="!$v.value.nutritions.carbohydrates.required" class="invalid-feedback d-block">Carbohydrates is required</div>
                </template>
              </div>
            </div>
            <div class="form-group row d-flex">
              <label class="col-sm-5 col-form-label">Carbohydrates of which sugar<span class="text-danger">*</span></label>
              <div class="col-sm-7">
                <input
                  v-model="$v.value.nutritions.sugarCarbohydrates.$model"
                  type="number"
                  step="any"
                  class="form-control"
                  :class="{'is-invalid': $v.value.nutritions.sugarCarbohydrates.$error}"/>
                <template v-if="$v.value.nutritions.sugarCarbohydrates.$dirty">
                  <div v-if="!$v.value.nutritions.sugarCarbohydrates.minValue" class="invalid-feedback d-block">Must be greater or equal to 0</div>
                  <div v-if="!$v.value.nutritions.sugarCarbohydrates.required" class="invalid-feedback d-block">Sugar carbohydrates is required</div>
                </template>
              </div>
            </div>
            <div class="form-group row d-flex">
              <label class="col-sm-5 col-form-label">Fibre <span class="text-danger">*</span></label>
              <div class="col-sm-7">
                <input
                  v-model="$v.value.nutritions.fibre.$model"
                  type="number"
                  step="any"
                  class="form-control"
                  :class="{'is-invalid': $v.value.nutritions.fibre.$error}"/>
                <template v-if="$v.value.nutritions.fibre.$dirty">
                  <div v-if="!$v.value.nutritions.fibre.minValue" class="invalid-feedback d-block">Must be greater or equal to 0</div>
                  <div v-if="!$v.value.nutritions.fibre.required" class="invalid-feedback d-block">Fibre is required</div>
                </template>
              </div>
            </div>
            <div class="form-group row d-flex">
              <label class="col-sm-5 col-form-label">Protein <span class="text-danger">*</span></label>
              <div class="col-sm-7">
                <input
                  v-model="$v.value.nutritions.protein.$model"
                  type="number"
                  step="any"
                  class="form-control"
                  :class="{'is-invalid': $v.value.nutritions.protein.$error}"/>
                <template v-if="$v.value.nutritions.protein.$dirty">
                  <div v-if="!$v.value.nutritions.protein.minValue" class="invalid-feedback d-block">Must be greater or equal to 0</div>
                  <div v-if="!$v.value.nutritions.protein.required" class="invalid-feedback d-block">Protein is required</div>
                </template>
              </div>
            </div>
            <div class="form-group row d-flex">
              <label class="col-sm-5 col-form-label">Salt <span class="text-danger">*</span></label>
              <div class="col-sm-7">
                <input
                  v-model="$v.value.nutritions.salt.$model"
                  type="number"
                  step="any"
                  class="form-control"
                  :class="{'is-invalid': $v.value.nutritions.salt.$error}"/>
                <template v-if="$v.value.nutritions.salt.$dirty">
                  <div v-if="!$v.value.nutritions.salt.minValue" class="invalid-feedback d-block">Must be greater or equal to 0</div>
                  <div v-if="!$v.value.nutritions.salt.required" class="invalid-feedback d-block">energyKCal is required</div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row">
          <div class="col-6">
            <form-description label="Weight" :value="`${value.nutritions.weight} ${(value.net_weight_uom || {}).name || 'Unit'}`"/>
            <form-description label="Energy KCal" :value="value.nutritions.energyKCal"/>
            <form-description label="EnergyKJ" :value="value.nutritions.energyKJ"/>
            <form-description label="Fat" :value="value.nutritions.fat"/>
            <form-description label="Saturates" :value="value.nutritions.saturates"/>
          </div>
          <div class="col-6">
            <form-description label="Carbohydrates" :value="value.nutritions.carbohydrates"/>
            <form-description label="Sugar Carbohydrates" :value="value.nutritions.sugarCarbohydrates"/>
            <form-description label="Fibre" :value="value.nutritions.fibre"/>
            <form-description label="Protein" :value="value.nutritions.protein"/>
            <form-description label="Salt" :value="value.nutritions.salt"/>
          </div>
        </div>
      </template>
    </div>
    <div v-if="edit" class="card-footer d-flex">
      <button class="btn btn-primary ml-auto" @click.prevent="validate">Apply</button>
    </div>
  </div>

</template>

<script>

import FormDescription from '@/components/FormDescription';
import {minValue, required} from 'vuelidate/lib/validators';

export default {

  components: {

    FormDescription,
  },
  props: {

    edit: {

      default: true,
      type: Boolean,
    },
    value: {

      required: true,
      type: Object,
    },
  },
  methods: {

    validate() {
      this.$v.value.nutritions.$touch();

      if (!this.$v.value.nutritions.$invalid) { this.$emit('valid'); }
    },
  },
  validations: {
    value: {
      nutritions: {
        carbohydrates: {
          minValue: minValue(0),
          required,
        },
        energyKCal: {
          minValue: minValue(0),
          required,
        },
        energyKJ: {
          minValue: minValue(0),
          required,
        },
        fat: {
          minValue: minValue(0),
          required,
        },
        fibre: {
          minValue: minValue(0),
          required,
        },
        protein: {
          minValue: minValue(0),
          required,
        },
        salt: {
          minValue: minValue(0),
          required,
        },
        saturates: {
          minValue: minValue(0),
          required,
        },
        sugarCarbohydrates: {
          minValue: minValue(0),
          required,
        },
        weight: {
          minValue: minValue(0),
          required,
        },
      },
    },
  },
};
</script>
