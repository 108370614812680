<template>
  <autocomplete
    :value="value"
    :list.sync="compositions"
    :nextPage="nextArticleCompositions"
    :multiple="multiple"
    :class="{'is-invalid': invalid}"
    :taggable="taggable"
    label="name"
    entity="composition"
    placeholder="Select compositions"
    @input="onSelect"
    @option:new="createNew"
  />
</template>

<script>
import {articleCompositions} from '@/services';
import Autocomplete from '@/components/Autocomplete';

export default {
  components: {
    Autocomplete,
  },
  props: {
    invalid: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      compositions: [],
    };
  },
  created() {
    articleCompositions.getByParameters({column: 'id', direction: 'asc', limit: 20}).then(result => this.compositions = result);
  },
  methods: {
    async nextArticleCompositions(page, query) {
      const params = {
        column: 'name',
        direction: 'asc',
        limit: 20,
        page,
      };

      if (query.length) params.query = query;

      const result = await articleCompositions.getByParameters(params);

      return result;
    },
    async onSelect(value) {
      const filterValue = value.filter(composition => typeof composition !== 'string');
      this.$emit('select', filterValue);
    },
    async createNew(value) {
      try {
        // add the new composition if response is string
        if (typeof value === 'string') {
          const {item} = await articleCompositions.post({name: value});

          this.$toasted.success('New article composition created');

          this.value.push(item);
        }
      }
      catch (ex) {
        console.error(ex);
        this.$toasted.error(`Something went wrong, ${Object.values(ex.response?.data?.errors || []).join(',')}`);
      }
    },
  },
};

</script>
