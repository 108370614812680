<template>
  <div>
    <div class="card-body" :class="{'was-validated': $v.value.$dirty}">
      <template v-if="edit">
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Compositions</label>
          <div class="col-sm-7">
            <article-composition-dropdown
              :class="{'is-invalid': $v.value.compositions.$dirty && $v.value.compositions.$invalid}"
              :value="value.compositions"
              multiple
              taggable
              @select="value => setValue(value)"/>
          </div>
        </div>
      </template>
      <template v-else>
        <form-description label="Compositions" :value="compositionNames.length ? compositionNames.join(', ') : 'N/A'"/>
      </template>
    </div>
    <div v-if="edit" class="card-footer d-flex">
      <button class="btn btn-primary ml-auto" @click.prevent="validate">Apply</button>
    </div>
  </div>
</template>

<script>

import FormDescription from '@/components/FormDescription';
import ArticleCompositionDropdown from '@/components/filters/ArticleCompositionDropdown';

export default {
  name: 'CompositionsForm',
  components: {
    FormDescription,
    ArticleCompositionDropdown,
  },
  props: {
    edit: {
      default: false,
      type: Boolean,
    },
    value: {
      required: true,
      type: Object,
    },
  },
  computed: {
    compositionNames() {
      return this.value.compositions.map(composition => composition.name);
    },
  },
  methods: {
    validate() {
      this.$v.value.$touch();

      if (!this.$v.value.$invalid) { this.$emit('valid'); }
    },
    setValue(value) {
      this.value.compositions = value;
    },
  },
  validations: {
    value: {
      compositions: {

      },
    },
  },
};

</script>
