export default {
  active: {
    class: 'bg-success text-success',
    name: 'Approved (Active)',
  },
  draft: {
    class: 'bg-warning text-warning',
    name: 'Pending (Draft)',
  },
  temporarily_discontinued: {
    class: 'bg-warning text-warning',
    name: 'Temporarily discontinued',
  },
  seasonally_discontinued: {
    class: 'bg-warning text-warning',
    name: 'Seasonally discontinued',
  },
  permanently_discontinued: {
    class: 'bg-danger text-danger',
    name: 'Permanently discontinued',
  },
};
