<template>

  <div>
    <div class="card-body">
      <template v-if="edit">
        <div class="form-group row d-flex">
          <label class="col-sm-4 col-form-label">How it will be used</label>
          <div class="col-sm-8">
            <textarea v-model="value.how_it_will_be_used" class="form-control"></textarea>
          </div>
        </div>
        <div class="form-group row d-flex">
          <label class="col-sm-4 col-form-label">Expiry date of excess</label>
          <div class="col-sm-8">
            <input
              v-model="value.expiry_date_of_excess"
              type="text"
              class="form-control"/>
          </div>
        </div>
        <div class="form-group row d-flex">
          <label class="col-sm-4 col-form-label">How to taste test</label>
          <div class="col-sm-8">
            <textarea v-model="value.how_to_taste_test" class="form-control"/>
          </div>
        </div>
        <div class="form-group row d-flex">
          <label class="col-sm-4 col-form-label">Has seasonality</label>
          <div class="col-sm-8">
            <label class="custom-switch mt-2">
              <input
                type="checkbox"
                class="custom-switch-input"
                :checked="value.has_seasonality"
                @change="value.has_seasonality = !value.has_seasonality">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description"></span>
            </label>
          </div>
        </div>
        <template v-if="value.has_seasonality">
          <div class="form-group row d-flex">
            <label class="col-sm-4 col-form-label">Season start <span class="text-danger">*</span></label>
            <div class="col-sm-8">
              <div class="form-row">
                <div class="form-group col mb-0">
                  <label class="form-label">Day</label>
                  <input
                    v-model="$v.value.season_start_day.$model"
                    type="number"
                    class="form-control"
                    :class="{'is-invalid': $v.value.season_start_day.$error}">
                  <template v-if="$v.value.season_start_day.$dirty">
                    <div v-if="!$v.value.season_start_day.required" class="invalid-feedback d-block">Season start day is required</div>
                    <div v-if="!$v.value.season_start_day.maxValue" class="invalid-feedback d-block">Season start day must be less than or equal to 31</div>
                  </template>
                </div>
                <div class="form-group col mb-0">
                  <label class="form-label">Month</label>
                  <select
                    v-model="$v.value.season_start_month.$model"
                    class="form-control custom-select"
                    :class="{'is-invalid': $v.value.season_start_month.$error}">
                    <option value="">Choose season start month</option>
                    <option
                      v-for="month in Months"
                      :key="month.value"
                      :value="month.value">
                      {{month.label}}
                    </option>
                  </select>
                  <template v-if="$v.value.season_start_month.$dirty">
                    <div v-if="!$v.value.season_start_month.required" class="invalid-feedback d-block">Season start month is required</div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row d-flex">
            <label class="col-sm-4 col-form-label">Season end <span class="text-danger">*</span></label>
            <div class="col-sm-8">
              <div class="form-row">
                <div class="form-group col mb-0">
                  <label class="form-label">Day</label>
                  <input
                    v-model="$v.value.season_end_day.$model"
                    type="number"
                    class="form-control"
                    :class="{'is-invalid': $v.value.season_end_day.$error}">
                  <template v-if="$v.value.season_end_day.$dirty">
                    <div v-if="!$v.value.season_end_day.required" class="invalid-feedback d-block">Season end day is required</div>
                    <div v-if="!$v.value.season_end_day.maxValue" class="invalid-feedback d-block">Season end day must be less than or equal to 31</div>
                  </template>
                </div>
                <div class="form-group col mb-0">
                  <label class="form-label">Month</label>
                  <select
                    v-model="$v.value.season_end_month.$model"
                    class="form-control custom-select"
                    :class="{'is-invalid': $v.value.season_end_month.$error}">
                    <option value="">Choose season end month</option>
                    <option
                      v-for="month in Months"
                      :key="month.value"
                      :value="month.value">
                      {{month.label}}
                    </option>
                  </select>
                  <template v-if="$v.value.season_end_month.$dirty">
                    <div v-if="!$v.value.season_end_month.required" class="invalid-feedback d-block">Season end month is required</div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <form-description label="How it will be used" :value="value.how_it_will_be_used"/>
        <form-description label="Expiry date of excess" :value="value.expiry_date_of_excess"/>
        <form-description label="How to taste test" :value="value.how_to_taste_test"/>
        <form-description label="Has seasonality" :value="value.has_seasonality ? 'Yes' : 'No'"/>
        <form-description v-if="value.has_seasonality">
          <label class="text-right font-weight-bold col-sm-5">Seasonality duration:</label>
          <div class="col-sm-7">
            {{value.season_start_day}} {{moment(value.season_start_month, 'M').format('MMMM')}} - {{value.season_end_day}} {{moment(value.season_end_month, 'M').format('MMMM')}}
          </div>
        </form-description>
      </template>
    </div>

    <div v-if="edit" class="card-footer d-flex">
      <button class="btn btn-primary ml-auto" @click.prevent="validate">Apply</button>
    </div>
  </div>

</template>

<script>

import FormDescription from '@/components/FormDescription';
import {requiredIf, maxValue} from 'vuelidate/lib/validators';
import Months from '@/assets/enums/Months';

export default {

  components: {
    FormDescription,
  },
  props: {

    edit: {

      default: false,
      type: Boolean,
    },
    value: {

      required: true,
      type: Object,
    },
  },
  data() {
    return {

      Months,
    };
  },
  methods: {

    validate() {
      this.$v.value.$touch();

      if (!this.$v.value.$invalid) { this.$emit('valid'); }
    },
  },
  validations: {

    value: {
      season_end_day: {
        maxValue: maxValue(31),
        required: requiredIf(function(value) {
          return this.value.has_seasonality;
        }),
      },
      season_end_month: {
        required: requiredIf(function(value) {
          return this.value.has_seasonality;
        }),
      },
      season_start_day: {
        maxValue: maxValue(31),
        required: requiredIf(function(value) {
          return this.value.has_seasonality;
        }),
      },
      season_start_month: {
        required: requiredIf(function(value) {
          return this.value.has_seasonality;
        }),
      },
    },
  },
};
</script>
