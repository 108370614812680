/**
 * The `articleEdit` mixin contains shared functionality between
 * Article and Article Requisition create/edit pages
 * because their behaviour is mostly common.
 *
 * The original functionality on the Article page is set up as the
 * default behaviour in the articleEdit mixin.
 *
 * This mixin itself uses the `edit` mixin for the base feature set.
 *
 * Any change made here needs to be tested on both Article and Requisition pages.
 */
import edit from '@/mixins/edit';
import {articles, uom} from '@/services';
import numeral from 'numeral';

const AVAILABLE_FOR_IGNORING_QUANTITY = ['purchasable-and-packable-materials', 'packable-materials'];

export default {
  mixins: [
    edit,
  ],
  data() {
    return {
      tabs: {
        material: {collapse: false, disabled: false, display: true, edit: true},
        general: {collapse: true, disabled: true, display: true, edit: false},
        replenishment: {collapse: true, disabled: true, display: false, edit: false},
        logistics: {collapse: true, disabled: true, display: false, edit: false},
        nutrition: {collapse: true, disabled: true, display: true, edit: false},
        storagePacking: {collapse: true, disabled: true, display: false, edit: false},
        other: {collapse: true, disabled: true, display: false, edit: false},
        allergens: {collapse: true, disabled: true, display: false, edit: false},
        compositions: {collapse: true, disabled: true, display: false, edit: false},
      },
      uom: [],
      withParams: [],
      logisticsCustomError: null,
    };
  },
  computed: {
    isPackable() {
      return !this.item.material_type?.is_purchasable &&
        !this.item.material_type?.has_multiple_purchasable &&
        this.item.material_type?.has_logistics &&
        this.item.material_type?.is_recipe_material;
    },
    formInvalid() {
      return ref => this.$refs[ref]?.$v?.$dirty && this.$refs[ref]?.$v?.$invalid;
    },
    isEditable() {
      return tab => !this.isEditing && !this.tabs?.[tab]?.edit && this.can((this.$route.meta.permissions || {}).update);
    },
    isEditing() {
      return Object.values(this.tabs).some(item => item.disabled);
    },
    route() {
      return `/articles/${this.item.id}`;
    },
  },
  created() {
    uom.getByParameters({column: 'name', direction: 'asc'}).then(result => this.uom = result.items);
  },
  mounted() {
    if (this.isNew && this.$route.query.duplicate) {
      this.fetchDuplicate(this.$route.query.duplicate);
    }
  },
  methods: {
    editForm(step) {
      // reset tabs
      Object.keys(this.tabs).filter(tab => tab !== step).forEach(value => {
        this.tabs[value].edit = false;

        // disabled sections when changing material type
        if (step === 'material') {
          this.tabs[value].disabled = true;
          this.tabs[value].collapse = true;
        }
      });

      this.tabs[step].edit = true;
      this.tabs[step].collapse = false;
    },
    async fetchData(id) {
      return articles.getById(id, {params: {with: this.withParams.join(',')}});
    },
    async fetchDuplicate(id) {
      const result = (await articles.getById(id, {params: {with: this.withParams.join(',')}}));

      // creating new doesn't need an id
      delete result.item.id;

      result.item.status = 'draft';

      // clean ids of replenishment and logistics
      result.item.replenishments?.map(item => {
        delete item.id;

        return item;
      });

      result.item.logistics?.map(item => {
        delete item.id;

        return item;
      });

      // transform data to standardize item data
      this.item = (this.transformData(result)).item;
      this.old = Object.assign({}, this.item);
    },
    async handleStatus(id, status) {
      await articles.updateStatus(id, status);
      this.refresh();
    },
    isAvailableForIgnoringQuantity(article = this.item) {
      return article.material_type !== undefined && AVAILABLE_FOR_IGNORING_QUANTITY.includes(article.material_type?.name);
    },
    nextStep(current, article = this.item) {
      this.tabs[current].edit = false;

      // when this step is completed there are certain sections that would show or be removed
      if (current === 'material') {
        this.tabs.replenishment.display = false;
        this.tabs.logistics.display = false;
        this.tabs.storagePacking.display = false;
        this.tabs.other.display = false;
        this.tabs.allergens.display = true;
        this.tabs.compositions.display = true;

        if (article.material_type.has_replenishment) this.tabs.replenishment.display = true;
        if (article.material_type.has_logistics) this.tabs.logistics.display = true;
        if (article.material_type.has_storage_and_packing) this.tabs.storagePacking.display = true;
        if (article.material_type.has_other) this.tabs.other.display = true;

        // this key section will enable all the forms
        Object.keys(this.tabs).forEach(item => {
          this.tabs[item].disabled = false;
        });
      }

      // get the next step tab index
      const filterTabs = Object.keys(this.tabs).filter(item => this.tabs[item].display);
      const currentIndex = filterTabs.findIndex(item => item === current);

      // collapse next step if it has next step
      if (currentIndex >= 0 && currentIndex < filterTabs.length - 1) {
        this.tabs[filterTabs[currentIndex + 1]].collapse = false;
        this.tabs[filterTabs[currentIndex + 1]].edit = true;
      }
    },
    prepareArticlePayload(article, logistics, replenishments, dependencies) {
      const payload = Object.assign({}, article, {
        brand_id: article.brand.id,
        gnfr_type_id: article.gnfr_type?.id || null,
        packaging_material_id: article.packaging_material?.id || null,
        packaging_article_id: article.packaging_article?.id || null,
        ingredient_id: article.ingredient?.id,
        is_temperature_controlled: !!article.is_temperature_controlled,
        material_type_id: article.material_type.id,
        net_weight_uom_id: article.net_weight_uom.id,
        nutritions: Object.assign({}, article.nutritions, {uom_id: article.net_weight_uom.id}),
        qty_per_net_weight_uom_id: article.qty_per_net_weight_uom.id,
        allergens: article.allergens.map(allergen => {
          if (typeof allergen === 'object' && !Array.isArray(allergen)) {
            return allergen.id;
          }

          // allergen requisition just returns id
          return allergen;
        }),
        countries: article.countries.map(country => {
          if (typeof country === 'object' && !Array.isArray(country)) {
            return country.id;
          }

          // allergen requisition just returns id
          return country;
        }),
      });

      delete payload.replenishments;
      delete payload.logistics;
      delete payload.dependencies;
      delete payload.gnfr_type;
      delete payload.packaging_material;
      delete payload.packaging_article;

      if (logistics.length) payload.logistics = logistics;
      if (replenishments.length) payload.replenishments = replenishments;
      if (dependencies.length) payload.dependencies = dependencies;

      return payload;
    },
    prepareArticleDependenciesPayload(dependencies) {
      return dependencies?.map(item => {
        const data = {
          article_id: item.article?.id,
          percentage: item.percentage,
        };

        return data;
      });
    },
    prepareArticleLogisticsPayload(logistics) {
      return logistics?.map(item => {
        const data = Object.assign({}, item, {
          alternative_unit_of_measure_id: item.alternative_uom.id,
          weight_unit_of_measure_id: item.weight_uom.id,
        });

        // clean data, api doesn't accept extra fields
        delete data.alternative_uom;
        delete data.packaging_method;
        delete data.unit_of_dimension;
        delete data.modelName;
        delete data.volume_uom;
        delete data.weight_unit_of_measure;
        delete data.weight_uom;

        return data;
      });
    },
    prepareArticleReplenishmentsPayload(replenishments) {
      return replenishments?.map(item => {
        const data = Object.assign({}, item, {
          delivery_days: item.delivery_days?.map(item => item.id),
          ordering_days: item.ordering_days?.map(item => item.id),
          ordering_uom_id: item.ordering_uom.id,
          supplier_id: item.supplier.id,
          article_id: this.item.id,
        });

        // clean data, api doesn't accept extra fields
        delete data.ordering_uom;
        delete data.supplier;
        delete data.modelName;

        return data;
      });
    },
    submitData(item) {
      const logistics = this.prepareArticleLogisticsPayload(item.logistics);
      const replenishments = item.material_type?.is_purchasable === true
        ? this.prepareArticleReplenishmentsPayload(item.replenishments)
        : [];
      const dependencies = this.prepareArticleDependenciesPayload(item.dependencies);
      const payload = this.prepareArticlePayload(item, logistics, replenishments, dependencies);

      payload.compositions = payload.compositions.map(composition => {
        if (typeof composition === 'object' && !Array.isArray(composition)) {
          return composition.id;
        }

        // composition articles just returns id
        return composition;
      });

      return articles.saveOrUpdate(payload, {params: {with: this.withParams.join(',')}});
    },
    transformArticleDependencies(article) {
      if (!article.dependencies) {
        return article;
      }

      article.dependencies = article.dependencies.map(item => {
        return {
          article: {
            id: item.article_id,
            name: item.article_name,
          },
          ingredient: {
            id: item.ingredient_id,
            name: item.ingredient_name,
          },
          percentage: item.percentage,
          article_allergens: item.article_allergens,
          article_is_import: item.article_is_import,
          article_countries: item.article_countries,
        };
      });

      // inherit countries, allergens and is_import if an article is a packable material
      if (article.dependencies.length && !article.material_type.is_purchasable) {
        articles.is_import = article.dependencies.some(article => article.article_is_import);
        article.countries = article.dependencies.flatMap(article => article.article_countries);
        article.allergens = article.dependencies.flatMap(article => article.article_allergens);
      }

      return article;
    },
    transformArticleNutritions(article, nutritionsDefaultsProp) {
      if (!nutritionsDefaultsProp) {
        nutritionsDefaultsProp = this.item.nutritions;
      }

      article.nutritions = Object.assign({}, nutritionsDefaultsProp, article.nutritions || {});

      return article;
    },
    transformArticleReplenishments(article) {
      if (!article.replenishments) {
        return article;
      }

      // format delivery days data
      article.replenishments = article.replenishments.map(item => {
        return Object.assign(item, {
          delivery_days: item.delivery_days?.map(value => value.weekday),
          is_preferred_supplier: !!item.is_preferred_supplier,
          ordering_days: item.ordering_days?.map(value => value.weekday),
          disable_cost_price: !!item.cost_price,
        });
      });
      return article;
    },
    transformData(result) {
      this.updateFormState(result.item);
      this.transformArticleNutritions(result.item);
      this.transformArticleReplenishments(result.item);
      this.transformArticleDependencies(result.item);

      return result;
    },
    updateArticleNames(article = this.item) {
      // this behavior auto generates a quickbook name or article name, it will override if you make changes on brand and net weight
      const standardName = (`${numeral(article?.net_weight).format('0,0[.]00') || ''} ${article?.net_weight_uom?.name || ''} ${article.brand?.name || ''} ${article?.ingredient?.name || ''}`).trim();

      article.name = standardName;
      article.replenishments?.map(replenishment => Object.assign(replenishment, {quickbook_name: standardName}));
    },
    updateFormState(article) {
      if (this.isNew) {
        return this;
      }

      // dynamically show required forms based on the material type
      if (article.material_type) {
        if (article.material_type.has_replenishment) this.tabs.replenishment.display = true;
        if (article.material_type.has_logistics) this.tabs.logistics.display = true;
        if (article.material_type.has_storage_and_packing) this.tabs.storagePacking.display = true;
        if (article.material_type.has_other) this.tabs.other.display = true;

        this.tabs.allergens.display = true;
        this.tabs.compositions.display = true;
      }

      // enable tabs if article is not new
      Object.keys(this.tabs).forEach(value => {
        this.tabs[value].disabled = false;
        this.tabs[value].collapse = false;
        this.tabs[value].edit = false;
      });

      return this;
    },
    async validate() {
      let valid = true;
      let scrollToErrorTop = -1;
      this.logisticsCustomError = null;

      // control scrolling in edit mixin
      this.customScrollToError = true;

      const tabs = Object.keys(this.tabs).filter(tab => !this.tabs[tab].disabled && this.tabs[tab].display);

      // on submit validation, should'nt be sent to api if any section is still not valid
      for (let i = 0; i <= tabs.length - 1; i++) {
        this.$refs[tabs[i]]?.$v?.$touch(); // eslint-disable-line

        await this.$nextTick();

        // this will revalidate our model first
        if (this.$refs[tabs[i]]?.$v?.$invalid) {
          this.tabs[tabs[i]].edit = true;
          this.tabs[tabs[i]].disabled = false;
          this.tabs[tabs[i]].collapse = false;

          // scroll to form error on the last error seen
          scrollToErrorTop = this.$refs[tabs[i]].$parent.$el?.offsetTop;

          if (valid) { valid = false; }
        }

        // check if at least 'each' logistics added
        if (tabs[i] === 'logistics' && this.item.logistics.length >= 1) {
          const validLogistics = valid = this.validateLogistics(this.item);
          if (!validLogistics) {
            scrollToErrorTop = this.$refs.logistics.$parent.$el?.offsetTop;
          }
        }
      }

      if (scrollToErrorTop > -1) {
        window.scrollTo({
          behavior: 'smooth',
          top: scrollToErrorTop,
        });
      }

      return valid;
    },

    validateLogistics(item) {
      const eachExist = item.logistics.some(it => it.alternative_uom.name.toLowerCase() === 'each');
      if (eachExist) return true;

      this.tabs.logistics.edit = true;
      this.logisticsCustomError = 'At least one logistics should have "Each" as alternative UOM.';
      return false;
    },
  },
};
