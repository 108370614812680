<template>
  <b-modal
    v-model="safeShow"
    size="md"
    title="Please swap articles before making change"
    hide-footer>
    <template slot="modal-header-close">
      <wbr/>
    </template>
    <p>
      This article is found in <strong>{{masterRecipeCount}} recipes</strong>, please do a master ingredient,
      ingredient and article swap (where relevant) to the new primary article before changing the rank.
    </p>
    <p>Remember to confirm portion size changes (if applicable) with the food team.</p>
    <p><strong>You will not be able to save this article until the swap is completed.</strong></p>
    <div class="d-flex justify-content-end pt-6">
      <button class="btn btn-danger mr-2" @click.prevent="safeShow = false">
        Ok, I will do the swap and come back to edit this article
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'RankSwapModal',
  props: {
    masterRecipeCount: {
      required: true,
      type: Number,
    },
    show: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    safeShow: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit('show', newValue);
      },
    },
  },
};
</script>
