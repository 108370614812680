<template>

  <div>
    <div class="card-body" :class="{'was-validated': $v.value.$dirty}">
      <template v-if="edit">
        <div class="form-group row d-flex">
          <label class="col-sm-5 col-form-label">Shelf life expiration date <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <div class="input-group">
              <input
                v-model="$v.value.shelf_life_expiration_date.$model"
                :disabled="isPackable"
                type="text"
                class="form-control"
                :class="{'is-invalid': $v.value.shelf_life_expiration_date.$error}"/>
              <div class="input-group-append">
                <div class="input-group-text">days</div>
              </div>
            </div>
            <template v-if="$v.value.shelf_life_expiration_date.$dirty">
              <div v-if="!$v.value.shelf_life_expiration_date.required" class="invalid-feedback d-block">Shelf life expiration date is required</div>
            </template>
          </div>
        </div>
        <div class="form-group row d-flex">
          <label class="col-sm-5 col-form-label">Temperature controlled</label>
          <div class="col-sm-7">
            <label class="custom-switch mt-2">
              <input
                type="checkbox"
                class="custom-switch-input"
                :checked="value.is_temperature_controlled"
                @change="value.is_temperature_controlled = !value.is_temperature_controlled">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description"></span>
            </label>
          </div>
        </div>
        <template v-if="value.is_temperature_controlled">
          <div class="form-group row d-flex">
            <label class="col-sm-5 col-form-label">Storage temperature <span class="text-danger">*</span></label>
            <div class="col-sm-7">
              <div class="input-group">
                <input
                  v-model="$v.value.storage_temperature_before_packing.$model"
                  type="number"
                  step="any"
                  class="form-control"
                  :class="{'is-invalid': $v.value.storage_temperature_before_packing.$error}"/>
                <div class="input-group-append">
                  <div class="input-group-text">°C</div>
                </div>
              </div>
              <template v-if="$v.value.storage_temperature_before_packing.$dirty">
                <div v-if="!$v.value.storage_temperature_before_packing.required" class="invalid-feedback d-block">Storage temperature is required</div>
              </template>
            </div>
          </div>
          <div class="form-group row d-flex">
            <label class="col-sm-5 col-form-label">Storage temperature after opening <span class="text-danger">*</span></label>
            <div class="col-sm-7">
              <div class="input-group">
                <input
                  v-model="$v.value.storage_temperature_after_packing.$model"
                  type="number"
                  step="any"
                  class="form-control"
                  :class="{'is-invalid': $v.value.storage_temperature_after_packing.$error}"/>
                <div class="input-group-append">
                  <div class="input-group-text">°C</div>
                </div>
              </div>
              <template v-if="$v.value.storage_temperature_after_packing.$dirty">
                <div v-if="!$v.value.storage_temperature_after_packing.required" class="invalid-feedback d-block">Storage temperature after opening is required</div>
              </template>
            </div>
          </div>
        </template>
        <div class="form-group row d-flex">
          <label class="col-sm-5 col-form-label">Expiry time after packing <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <div class="input-group">
              <input
                v-model="$v.value.expiry_time_after_packing.$model"
                :disabled="isPackable"
                type="text"
                class="form-control"
                :class="{'is-invalid': $v.value.expiry_time_after_packing.$error}"/>
              <div class="input-group-append">
                <div class="input-group-text">days</div>
              </div>
            </div>
            <template v-if="$v.value.expiry_time_after_packing.$dirty">
              <div v-if="!$v.value.expiry_time_after_packing.required" class="invalid-feedback d-block">Expiry time after packing is required</div>
            </template>
          </div>
        </div>
        <div class="form-group row d-flex">
          <label class="col-sm-5 col-form-label">Production lead time <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <div class="input-group">
              <input
                v-model="$v.value.production_lead_time.$model"
                :disabled="isPackable"
                type="number"
                class="form-control"
                :class="{'is-invalid': $v.value.production_lead_time.$error}"/>
              <div class="input-group-append">
                <div class="input-group-text">days</div>
              </div>
            </div>
            <template v-if="$v.value.production_lead_time.$dirty">
              <div v-if="!$v.value.production_lead_time.required" class="invalid-feedback d-block">Production lead time is required</div>
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <form-description label="Shelf life expiration date" :value="value.shelf_life_expiration_date"/>
        <form-description label="Temperature controlled" :value="value.is_temperature_controlled ? 'Yes' : 'No' "/>
        <form-description
          v-if="value.is_temperature_controlled"
          label="Storage temperature"
          :value="`${value.storage_temperature_before_packing} &#xB0;C`"/>
        <form-description
          v-if="value.is_temperature_controlled"
          label="Storage temperature after opening"
          :value="`${value.storage_temperature_after_packing} &#xB0;C`"/>
        <form-description label="Expiry time after packing" :value="value.expiry_time_after_packing"/>
        <form-description label="Production lead time" :value="value.production_lead_time"/>
      </template>
    </div>
    <div v-if="edit" class="card-footer d-flex">
      <button class="btn btn-primary ml-auto" @click.prevent="validate">Apply</button>
    </div>
  </div>

</template>

<script>

import {required, requiredIf} from 'vuelidate/lib/validators';
import FormDescription from '@/components/FormDescription';

export default {

  components: {
    FormDescription,
  },
  props: {

    isPackable: {

      default: true,
      type: Boolean,
    },

    edit: {

      default: false,
      type: Boolean,
    },
    value: {

      required: true,
      type: Object,
    },
  },
  methods: {

    validate() {
      this.$v.value.$touch();

      if (!this.$v.value.$invalid) { this.$emit('valid'); }
    },
  },
  validations: {

    value: {

      expiry_time_after_packing: {
        required,
      },
      shelf_life_expiration_date: {
        required,
      },
      storage_temperature_after_packing: {
        required: requiredIf(function(value) {
          return this.value.is_temperature_controlled;
        }),
      },
      storage_temperature_before_packing: {
        required: requiredIf(function(value) {
          return this.value.is_temperature_controlled;
        }),
      },
      production_lead_time: {
        required,
      },
    },
  },
};
</script>
